import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const SERVER_URL = "http://localhost:5001/api/v1";

//chinmay
function formatCheckInTime(time) {
  if (time) {
    const utcDate = new Date(time);
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Kolkata",
    };
    const dateString = utcDate.toLocaleDateString("en-IN", options);

    return dateString;
  }
  return null;
}

const getUsers = async (page = 1) => {
  try {
    const res = await fetch(
      `https://server.warpbay.com/api/v1/user/get-all-users?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    const response = await res.json();
    // console.log(response)
    return response;
  } catch (err) {
    console.log(err);
  }
};

const RegistrationTabs = ({}) => {
  const [total, setTotal] = React.useState(null);
  const [current, setCurrent] = React.useState(1);
  const [users, setUsers] = React.useState([]);


  const totalPagesMemo = React.useMemo(() => {
    let totalPages = total / 10;
    totalPages = Math.floor(totalPages);
    console.log(totalPages);
    if (total % 10 > 0) totalPages++;
    console.log(totalPages);
    return totalPages;
  }, [total]);

  const totalPages = totalPagesMemo;
  const pagination = React.useMemo(() => {
    const previous = current - 1 > 0 ? current - 1 : null;
    const next = current + 1 <= totalPages ? current + 1 : null;
    return {
      current,
      previous,
      next,
      totalPages,
    };
  }, [current, totalPages]);

  const { previous, next } = pagination;

  React.useEffect(() => {
    getUsers(current)
      .then((response) => {
        setUsers(response.users);
        setTotal(response.count);
      })

      .catch((err) => console.log(err));
    // console.log(response)
  }, [current]);

  const handlePrevious = () => {
    setCurrent((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrent((prev) => prev + 1);
  };

  const handlePageChange = (page) => {
    setCurrent(page);
  };

  return (
    <div className="w-max border-slate-50 p-6 rounded-lg border-2 overflow-x-auto shadow-lg shadow-slate-400">
      {/* <div> */}
      {users?.length > 0 ? (
        <>
          <table className="table-auto max-xl:w-[1020px] rounded-md">
            <thead className="">
              <tr className="">
                <th className="text-[16px] font-[600] text-left leading-[45px] w-[150px]">
                  First Name
                </th>
                <th className="text-[16px] font-[600] text-left leading-[45px] w-[150px]">
                  Last Name
                </th>
                <th className="text-[16px] font-[600] text-left leading-[45px] w-[250px]">
                  Email
                </th>
                <th className="text-[16px] font-[600] text-left leading-[45px] w-[150px]">
                  Mobile
                </th>
                <th className="text-[16px] font-[600] text-left w-[200px]">
                  Organization
                </th>
                <th className="text-[16px] font-[600] text-left w-[250px]">
                  Created
                </th>
                <th className="text-[16px] font-[600] text-left w-[250px]">
                  Last Updated
                </th>
              </tr>
            </thead>

            <tbody className="md:w-[1020px]">
              {users.map((attendee, index) => {
                return (
                  <>
                    <tr className="h-[55px] border-b">
                      <td className="text-[12px] font-[500]]">
                        {attendee.firstName}
                      </td>
                      <td className="text-[12px] font-[500]">
                        {attendee.lastName}
                      </td>
                      <td className="text-[12px] font-[500]">
                        {attendee.email}
                      </td>
                      <td className="text-[12px] font-[500]">
                        {attendee.mobile ? attendee.mobile : "-"}
                      </td>

                      <td className="text-[12px] font-[500]">
                        {attendee.organization}
                      </td>
                      <td className="text-[12px] font-[500]">
                        {formatCheckInTime(attendee.createdAt)}
                      </td>
                      <td className="text-[12px] font-[500]">
                        {formatCheckInTime(attendee.updatedAt)}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          {/* {tab === "Registered" ? ( */}

          <div className="flex items-center ml-0  rounded-lg w-full mx-auto mt-[10px]">
            <div className="text-[13px] mr-2">
              <span className="font-semibold text-[14px]">{users?.length}</span>{" "}
              of <span className="font-semibold text-[14px]">{total}</span>{" "}
              results
            </div>

            <button
              className={`text-center cursor-pointer text-${
                previous === null ? "gray-300" : "primary"
              } text-[23px]`}
              disabled={previous === null ? true : false}
              onClick={() => handlePrevious()}
            >
              {"<"}
            </button>

            <div className="mx-3 cursor-pointer">
              <select
                id="title"
                name="title"
                // {...register("gender")}
                required
                value={current}
                onChange={(e) => {
                  handlePageChange(e.target.value);
                  // await getAllEventAttendees(next);
                }}
                className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
              bg-center `}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={next === null ? true : false}
              className={`text-center cursor-pointer text-${
                next === null ? "gray-300" : "primary"
              } text-[23px]`}
              onClick={() => handleNext()}
            >
              {">"}
            </button>
          </div>
          {/* ) : (
            <></>
          )} */}
        </>
      ) : (
        <div className="grid w-full place-items-center h-[350px] mt-[40px]">
          <div>
            {/* <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[500px] h-[300px]"
            /> */}
            <p className="text-4xl font-[500] text-[#717171] text-center mt-[15px]">
              Nothing here...
            </p>
          </div>
        </div>
      )}
      <div className="text-gray-300 text-primary"></div>
    </div>
  );
};

function App() {
  return (
    <Router>
    <div className="flex justify-center items-center h-screen">
    <Routes>
          <Route path="/" element={<RegistrationTabs />} />
          {/* <Route path="/events" element={<Events />} /> */}
        </Routes>
    </div>
    </Router>
  );
}

export default App;
