import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// React.useEffect(() => {
    
//   fetch("http://localhost:5001/api/v1/user/find-users", {
//     method: 'POST',
//     headers: {
//       'Content-type': 'application/json'
//     },
//     body: JSON.stringify({ page: p)
//   })
//     .then(async (res) => {
//       const response = await res.json();
//       console.log('Response:', response);
//       setNext(response.next);
//       setUsers(response.users);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }, [next]);